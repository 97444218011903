

































import { defineComponent, PropType } from '@nuxtjs/composition-api';
import CtaPartial from '@theme/components/molecules/CtaPartial.vue';
import { CtaInterface } from '@amplience/types';

export default defineComponent({
  name: 'Banner',
  components: {
    CtaPartial,
  },
  props: {
    contentPosition: {
      type: String,
      default: ''
    },
    cta: {
      type: Object as PropType<CtaInterface>,
      default: () => ({}),
    },
    description: {
      type: String,
      default: ''
    },
    heading: {
      type: String,
      default: ''
    },
    backgroundImage: {
      type: Object,
      default: () => ({}),
    },
    textColor: {
      type: String,
      default: 'Neutral-0 - White'
    }
  },
  setup(props) {
    const isBackgroundImage = (): boolean => !!props.backgroundImage?.poi?.image;

    const isCallToAction = (cta: CtaInterface): boolean => {
      return !!(cta?.url && cta?.type && cta?.title);
    }

    const ctaParams = (cta: CtaInterface) => {
      return {
        linkType: cta.linkType,
        title: cta.title || '',
        url: cta.url || '',
        type: cta.type || 'Primary',
        newWindow: !!(cta.newWindow),
        noFollow: !!(cta.noFollow),
        trackingQuery: cta.trackingQuery || '',
      }
    };

    return {
      ctaParams,
      isCallToAction,
      isBackgroundImage,
      sources: [
        {
          aspect: '1:1',
          height: 316,
          media: '(max-width: 767px)',
        },
        {
          aspect: '4:1',
          height: 300,
          media: '(min-width: 768px)',
        },
      ],
    };
  }
})
