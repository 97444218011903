import { render, staticRenderFns } from "./Banner.vue?vue&type=template&id=8b22b78a&scoped=true"
import script from "./Banner.vue?vue&type=script&lang=ts"
export * from "./Banner.vue?vue&type=script&lang=ts"
import style0 from "./Banner.vue?vue&type=style&index=0&id=8b22b78a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8b22b78a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RenderPOI: require('/var/www/modules/amplience/components/render/POI.vue').default})
